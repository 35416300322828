import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import { BsFillCaretDownFill,BsFillCaretUpFill } from "react-icons/bs";
import { Col, Row } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

export default class MapTable extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false,
                       selectedMaps: [] };

        this.headerClick = this.headerClick.bind(this);
        this.mapClick = this.mapClick.bind(this);
      }


    headerClick(){
        this.setState(prevState => ({
            visible: !prevState.visible
        }));
    }

    mapClick(want,name){

        this.props.updateSelectedMaps(this.props.tier,want,name);

    }

    buildtable(want,jdata,tier,width){

        let maptable = jdata.lines.filter( x => x.mapTier===tier );

        maptable.sort( (f,s) => f.name.localeCompare( s.name ) )

        let td = [];

        let count = 1;

        let rows = [];



        //if(visible){
            maptable.forEach( (map) => {
                
                const imgCol = <Col>
                <LazyLoad height={45} width={45} once >
                <img className={ this.props.selectedMaps.some(y => want === y.want && map.name === y.name) ? "MapImg MapImgSelected" : "MapImg"}
                src={map.icon} 
                alt={"Tier " + tier + " " + map.name}
                id={ ((want ? "want" : "have") + " " + tier + " " + map.name).replace(/\s+/g, '-') }
                title={"Tier " + tier + " " + map.name}
                 />
                </LazyLoad>
                </Col>

                if(this.props.showMapText){
                    rows.push(<Col onClick={() => this.mapClick(want,map.name)}><Row>{imgCol}</Row>
                        <Row>
                        <Col>
                        <p className='mapSelectionText'>{map.name.replace(" Map","")}</p>
                        </Col>
                        </Row>
                        </Col>);
                } else {

                    rows.push(imgCol)
                }
                
                

                if(count % width === 0 || maptable.length === count ){

                //even out the grid with coloumns for the sake of symmetry 
                if(maptable.length === count && maptable.length >= width && count % width !== 0 ){
                    const sparecount = width - maptable.length % width

                    for(let i=1; i<=sparecount;i++){
                        rows.push(<Col/>)
                    }

                }

                td.push(<Row 
                    className={this.props.showMapText ? "py-2" : "py-3"}>
                    {rows}
                    </Row>);
                rows = [];
                }

                count +=1;

            } 
            );
        //}

        

        return td;

    }

      
render(){

    const {visible} = this.state;

    let caret;

    if(visible){
        caret = <BsFillCaretUpFill 
        id={"shcarrot-tier-" + this.props.tier } 
        />;
    }
    else{
        caret = <BsFillCaretDownFill 
        id={"shcarrot-tier-" + this.props.tier } 
        />;
    }

    return(
    <div>
        <Row className="mt-2"> <p className="mapSelectionTierText" onClick={() => this.headerClick()}>Tier {this.props.tier} {caret}</p>
        </Row>
        <Collapse in={visible} onEntered={() => forceCheck()}><div>
        <Row className="justify-content-center">
            <Col sm="auto">
                <Row className="border rounded border-secondary">
                    <Col className='px-3 pt-2 pb-1'>
                        {this.buildtable(true,this.props.mapdata,this.props.tier,5) }
                    </Col>
                </Row>
            </Col>
            <Col sm={1}/>
            <Col sm="auto">
                <Row className="border rounded border-secondary">
                    <Col className='px-3 pt-2 pb-1'>
                        {this.buildtable(false,this.props.mapdata,this.props.tier,5) }
                    </Col>
                </Row>
            </Col>
        </Row>
        </div></Collapse>  
    </div>
    )
        
    
}

}