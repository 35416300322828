import React, {useEffect, useState} from 'react';
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
import HowTo from '../Routes/HowTo';
import AuthError from '../Routes/AuthError';
import MainNavbar from './MainNavbar'
import MainContainer from './MainContainer';

function MainRouter(props){

    const [loginData, setLoginData] = useState([]);

    useEffect( () =>{

        fetch('https://poemap.trade/srv/account',{method: 'GET' , credentials: "include"})
        .then(response => response.json())
        .then(data =>{

            setLoginData(data);
        })

    },[] )

    return(
    <BrowserRouter>
        <MainNavbar loginData={loginData}/>
        <Routes>
            <Route path="/" element={<MainContainer loginData={loginData} />} />
            <Route path="howto" element={<HowTo />} />
            <Route path="autherror" element={<AuthError />} />
        </Routes>
    </BrowserRouter>
    );

}

export default MainRouter;