import React from 'react';
import {Row,Col,Container,Navbar,Nav,NavDropdown} from 'react-bootstrap'
import { Link } from "react-router-dom";

function MainNavbar(props){


function setLoginNav(){

  if(props.loginData['logged_in']){
       /*return(<NavDropdown title={"Logged in as " + props.loginData['accountName']} id="basic-nav-dropdown">
      <NavDropdown.Item href="https://poemaps.trade/srv/logout">Logout</NavDropdown.Item>
    </NavDropdown>)*/
    return([<Navbar.Text className='text-muted'>Logged in as</Navbar.Text>,
      <NavDropdown title={props.loginData['accountName']} id="basic-nav-dropdown">
      <NavDropdown.Item href="https://poemap.trade/srv/logout">Logout</NavDropdown.Item>
    </NavDropdown>]);
  }
  else{
   return(<Nav.Link  href="https://poemap.trade/srv/login">Login</Nav.Link>);
  }

}

    return(
      <Row>
        <Col>
        <Navbar bg="primary" expand="md" variant='dark'>
          <Container fluid>
    <Navbar.Brand as={Link} to="/">PoeMap.Trade</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link key="home" as={Link} to="/">Home</Nav.Link>
        <Nav.Link key="howto" as={Link} to="/howto">How To & Notes</Nav.Link>
        <Nav.Link key="discord" href="https://discord.gg/5ZE9FE6Cc5">Join Our Discord</Nav.Link>
      </Nav>
      <Nav>
      {setLoginNav()}
      </Nav>
    </Navbar.Collapse>
    </Container>
</Navbar>
</Col>
</Row>)

}

export default MainNavbar;