import React from 'react';
import { Container,Col,Row,Card } from 'react-bootstrap';
import '../howto.css'

function HowTo(props){

    return(
        <div>
        <Container>
            <Row className="justify-content-center align-items-center mt-6 mb-5">
            <Col className="col-auto">
                <Card border="primary" style={{ maxWidth: '800px'}}>
                <Card.Header as="h5" className="text-center">How To Trade</Card.Header>
                    <Card.Body>
                        <Card.Text>
                        <ul>
                            <li>Log into your Path of Exile account by clicking 'Login' on the top navbar</li>
                            <li>After logging in successfully scroll to the bottom of the home page and choose the character
                                you're currently logged in with and would like to trade with</li>
                            <li>Click on the maps that you have and want to trade in the "Have" column. 
                                Then select the maps you would like to obtain from the "Want" column. 
                            </li>
                            <li>Once you have all relavent maps selected, click "Start Trading"</li>
                            <li>If there are any other users that have maps they would like to trade
                                for you they will appear at the bottom of the home page below the Start
                                and Stop trading buttons</li>
                            <li>Once you've found a trade you would like to partake in click the Message Icon on the right of the trade row,
                                this will copy a message to the user you can paste into your POE client
                            </li>
                            <li>When you're done click Stop Trading</li>
                            <li>If you are current trading and would like to change the maps you are trading
                                you will need to stop trading, makes changes to your map selection and
                                click Start Trading again</li>
                        </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            </Row>
            <Row className="justify-content-center align-items-center mb-3">
            <Col className="col-auto">
                <Card border="primary" style={{ maxWidth: '800px'}}>
                <Card.Header as="h5" className="text-center">Notes</Card.Header>
                    <Card.Body>
                        <Card.Text>
                        <ul>
                            <li>The currently supported POE version is <b>3.25</b> in all public trade leagues</li>
                            <li>Trades are currently limited to the same tier. You can only Trade Tier 3 maps for a Tier 3 Map</li>
                            <li>There's currently no way to specify if you want a map to be normal, magic or rare. 
                                You will need to work that out with the other player when you request a trade</li>
                            <li>The Ignore Watchstone Maps checkmark box will cause the site to not display any maps as a result of watchstone upgrades. 
                                So if there is a tier 2 map and upon socketing a watchstone into your atlas there is also a teir 7 and tier 13 version of that map 
                                only the tier 2 version will be displayed.
                            </li>
                            <li>Only the PC realm is supported currently</li>
                            <li>Ruthless is not supported</li>
                            <li>Hover over the image of any map in the UI to get its name and tier</li>
                            <li>PoeMap.Trade is reactive and will work on mobile devices but it is far from optimized for it, it's largely inteded to be used on a desktop where you are also playing POE</li>
                            <li>Due to the sheer volume of maps in standard league the performance for standard league may not be the best. 
                                There are an incredible number of images to represent every possible standard map and that takes alot of resources to load.
                                Its recommended to only open the tiers that you need and be patient as they load. This will hopefully be improved in the future</li>
                            <li>Becuase the POE API requires any application to have this displayed: "This product isn't affiliated with or endorsed by Grinding Gear Games in any way"</li>
                        </ul>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            </Row>
        
        </Container>
        </div>
    );

}

export default HowTo;