import React from 'react';
import { Col,  Row, Card } from 'react-bootstrap';

export let Announcement = <Row className="justify-content-center align-items-center my-3">
          <Col className="col-auto">
            <Card bg='secondary' text='white' border='primary' style={{ width: '40rem' }}>
              <Card.Body>
                <Card.Title>
                  PoeMap.Trade is partially updated for 3.25. Map icons will be updated when that data becomes available<br></br>
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>


/* export let Announcement = null */