import React, {useEffect, useRef, useState} from 'react';
import { io } from 'socket.io-client';
import { Button, Col, Row, Card, Form } from 'react-bootstrap';
import TradeRows from './TradeRows'
import useSound from 'use-sound';

let socket;
const CLIENT_VERSION = 1.05

function TradePane(props){

    const [trades,setTrades] = useState([]);
    const [started,setStarted] = useState(false);
    const [playNotif,setPlayNotif] = useState({enabled:true,lastplay: new Date(0)});
    const [socketError,setSocketError] = useState({error: false, type: null}); 
    const prevTrades = usePrevious(trades);

    const [notif] = useSound('notification.mp3')

    useEffect(() => {
        if(process.env.NODE_ENV === 'production'){
            socket = io("https://poemap.trade",{path:'/srv/socket.io', query: 'version=' + CLIENT_VERSION});
        }
        else{
            //socket = io("http://192.168.1.51:10000");
            socket = io("https://poemap.trade",{path:'/srv/socket.io', query: 'version=' + CLIENT_VERSION});
        }
            
        

        socket.on('addTrade', (json) => addTrade(json));
        socket.on('removeTrade',(user) => removeTrade(user) );

        //socket.on('version_error', () => handleSocketError("version"))
        socket.on('connect_error', () => handleSocketError("error"));
        socket.on('connect_failed', () => handleSocketError("failed"));

        //on connect clear and general type connections errors
        socket.on('connect',() =>{  setSocketError( prevstate =>{
            if(prevstate.error && prevstate.type === "general"){
                return {error: false,type: null}
            }
            else{
                return prevstate
            }
        })})




        return () => {
            //console.log("Shutting down socket");
            if(socket) socket.disconnect();
        }
      }, []);

      function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }

      useEffect(()=>{

        if(prevTrades !== undefined && prevTrades.length < trades.length ){
            notifPlay();
        }

      },[trades])


      function notifPlay(){

        if(playNotif.enabled && Date.now() - playNotif.lastplay >= 4000){

            notif();

            setPlayNotif(prev => ({enabled:prev.enabled, lastplay: Date.now() }) )
        }
      }

    function handleSocketError(error){


        if(error === "version"){
            setSocketError({error:true,type:"version"});
        }
        else{
            setSocketError({error:true,type:"general"});
        }
    }
        

    function removeTrade(user){


        setTrades( (prevstate) => {

            return prevstate.filter( (trade) => trade.user !== user)

        });

    }

    function addTrade(json){

        console.log("trade added")
        console.log(json)

        let x = createTradesLookup(json["want"],{},true);
        let tradeLookup = createTradesLookup(json["have"],x,false);

        let trades = createTradesArray(tradeLookup,json["character"]);

        console.log("push t")
        console.log(trades)

        setTrades(prevTrades => prevTrades.concat(trades));
    }

    function createTradesLookup(input,currentTable,want) {
        let worh = (want) ? "want" : "have";

        //console.log("current table")
        //console.log(t)
        return input.reduce(function (table, item) {
          if (!table[item.user]) {
            table[item.user] = {}
          }
          if (!table[item.user][item.tier]) {
            table[item.user][item.tier] = []
          }
          if (!table[item.user][item.tier][worh]) {
            table[item.user][item.tier][worh] = []
          }
          table[item.user][item.tier][worh].push(item.mapname)
          return table
        }, currentTable)
      }

      function createTradesArray(lookup,charlookup){
            let trades = [];
            for(var user in lookup){

                for(var tier in lookup[user]){
                    trades.push(
                        {"user": user,
                        "tier": tier,
                        "want": lookup[user][tier]["want"],
                        "have": lookup[user][tier]["have"],
                        "messaged": false,
                        "character" : charlookup[user]
                        }
                    )
                }
            }

            return trades;
      }

    function messaged(index){
        setTrades( prevtrades =>{
        
            let newtrades = [...prevtrades]

            newtrades[index]["messaged"] = true;
            
            return newtrades;
        } )
    }

    function startClick(){

        notifPlay();

        socket.emit("startTrade", JSON.stringify(props.selectedMaps), props.character, props.league );
        
        setStarted(true);
    }


    function stopClick(){

        socket.emit("stopTrade");
        setTrades([]);
        
        setStarted(false);
    }

    function playNotifClick(){

        setPlayNotif( (prevstate) => ({enabled:!prevstate.enabled,lastplay:prevstate.lastplay} ));

    }

    function createIconLookup(){
        let lookup = [];

        for(let t = 1; t <= 17; t++){
            lookup[t]=[];
        }

        

        props.mapdata.lines.forEach(m => {

            if(typeof m.mapTier !== 'undefined'){
                lookup[m.mapTier][m.name] = m.icon;
            }
        });

        return lookup;
    }

    function IsStartDisabled(){
        //when start is allowed
        if(!started && !socketError.error && props.character !== ""){
            return false
        }
        else{
            return true
        }
    }

    function socketErrorNotification(){
        let versionerror = "The version of your client is outdated, please refresh the page"
        let generalerror = "There was an issue connecting to the trade server, try again later"

        if(socketError.error){
            return(

                <Row className="justify-content-center align-items-center my-6">
                <Col className="col-auto">
                <Card bg='danger' text='white' border='danger' style={{ width: '35rem' }}>
                <Card.Body>
                <Card.Title>Connection Error</Card.Title>
                <Card.Text> 
                    {socketError.type === "version" ? versionerror : generalerror}
                </Card.Text>
                </Card.Body>
                </Card>
                </Col>
                </Row>
            )

        }
        else
            return null

    }

    return(
    <div>
        <Row className='justify-content-md-center mt-4'>
            <Col sm="auto">
                <Button variant="success" onClick={startClick} type="start" disabled={IsStartDisabled()} >Start Trading</Button>
            </Col>
            <Col sm="auto">
                <Button variant="danger" onClick={stopClick} type="stop" disabled={!started}>Stop Trading</Button>
            </Col>
        </Row>
        <Row className='justify-content-md-center pt-2 my-2'>
            <Col sm="auto">
                <Form className="mx-2">
                    <Form.Check checked={playNotif.enabled} onChange={playNotifClick} label="Sound Notifications"/>
                </Form>
            </Col>
            <Col sm="auto">
                <Form className="mx-2">
                    <Form.Check checked={props.showMapText} onChange={props.showMapTextClick} label="Show Map Names"/>
                </Form>
            </Col>
            <Col sm="auto">
                <Form className="mx-2">
                    <Form.Check checked={props.ignoreWatchstoneMaps} onChange={props.ignoreWatchstoneMapsClick} label="Ignore Watchstone Maps"/>
                </Form>
            </Col>
        </Row>
        {socketErrorNotification()}
        <TradeRows trades={trades} messaged={messaged} icons={createIconLookup()} />
    </div>
    );
    
}

export default TradePane;