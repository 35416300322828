import React from 'react';
import { Container,Col,Row } from 'react-bootstrap';

function AuthError(props){

    return(
        <div>
        <Container>
            <Row className="justify-content-center align-items-center my-8">
                <Col className="col-sm-auto bg-secondary rounded">
                    <Row className="border-bottom my-4"><Col className="d-flex justify-content-center mb-2"><h4>Auth Error</h4></Col></Row>
                    <Row className="my-3">
                        <Col>
                        <p>Something went wrong well authenticating your POE Account...</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        </div>
    );

}

export default AuthError;