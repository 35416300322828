import React, { useEffect } from 'react';
import {Tooltip, OverlayTrigger,Container, Col, Row } from 'react-bootstrap';
import { BsArrowReturnLeft, BsFillChatSquareTextFill } from "react-icons/bs";


function TradeRows(props){


    function messageClick(i){
        const t = props.trades[i];
        props.messaged(i);
        
        console.log(t)
        let tradeMessage = ""

        if(t.have && t.want){
            tradeMessage = `@${t.character} Hi, I would like to trade my Tier ${t.tier} (${t.have.join(',')}) for your Tier ${t.tier} (${t.want.join(',')})`;
        }

        navigator.clipboard.writeText(tradeMessage);
    }

    function createRows(){
        let rjsx = [];

        
        props.trades.forEach( (trade,i) => {
            let row = []

            if(!trade.have || !trade.want){
                return;
            }

            //TIER
            let cn = ["tradecol",
                    "py-2",
                    "align-self-center"]
            row.push(<Col xs="auto" className={cn.join(" ")}>{"Tier-" + trade.tier}</Col> )

            //WANT
            let wimg = [];
            trade.want.forEach( (w,i) =>{
                let src = props.icons[trade.tier][w];
                wimg.push(<Col className="my-1 mx-2 bg-dark rounded" xs="auto">
                
                <Row><Col><img className="tradeicon p-1" title={w} alt={w} src={src} /></Col></Row>
                
                <Row><Col><div className='tradeText'>{w.replace(' Map','')}</div></Col></Row>
               
                </Col>)
            })
            
            cn = ["tradecol",
                  "px-3"]
            row.push(<Col className={cn.join(" ")}>
                <Row className="border-bottom border-dark">
                        <h6><b>Want</b></h6>
                    </Row>
                <Container><Row className='justify-content-start'>{wimg}</Row></Container>
                </Col>)


            let himg = [];
            //HAVE
      
        
                trade.have.forEach( (h,i) =>{
                    let src = props.icons[trade.tier][h];
                    himg.push(<Col className="my-1 mx-2 bg-dark rounded" xs="auto">
                
                    <Row><Col><img className="tradeicon p-1" title={h} alt={h} src={src} /> </Col></Row>

                    <Row><Col><div className='tradeText'>{h.replace(' Map','')}</div></Col></Row>
               
                    </Col>)
                })
   

            cn = ["tradecol",
                  "px-3"]
            row.push(<Col className={cn.join(" ")}>
                    <Row className="border-bottom border-dark">
                        <h6><b>Have</b></h6>
                    </Row>
                    <Container><Row className='justify-content-start'>{himg}</Row></Container>
                    </Col>)

            //USER
            cn = ["tradecol",
                    "px-3",
                "align-self-center"]
            row.push(<Col xs="auto" className={cn.join(" ")}><p className='tradeCharName'>{trade.character}</p></Col>)



            //BUTTONS

            /*const messageTooltip = React.forwardRef( (props,ref) =>(
                <Tooltip id={uuidv4()} placement="top" ref={ref} {...props}> 
                Message user to trade
                </Tooltip>
            ));*/

            const messageTooltip = (props) => (
                <Tooltip {...props}>
                  Copy the trade message to your clipboard
                </Tooltip>
              );

            cn = ["tradecol",
                "align-self-center",
                "px-3",
                "py-1"]
            row.push(<Col xs="auto" className={cn.join(" ")}>
                <OverlayTrigger
                placement='top'
                delay={{ show: 1000, hide: 200 }}
                overlay={messageTooltip}>
                    <span>
                        <BsFillChatSquareTextFill  
                        className = {props.trades[i]["messaged"] ? "msent" : ""} 
                        onClick={() => messageClick(i)} />
                    </span>
                </OverlayTrigger>
                </Col>)

            //rjsx.push(<Row className="mt-3"><Col className="col-2"/>{row}<Col className="col-2"/></Row>)
            
            cn = [
                "my-3",
                "traderow",
                "bg-secondary",
                "rounded",
            "justify-content-center"]
            rjsx.push(<Container><Row className={cn.join(" ")}>{row}</Row></Container>)
        });


        //console.log("RJSX")
        //console.log(rjsx)

        return rjsx;

    }


    return(
        <div>
        {createRows()}
        </div>
        );

}
export default TradeRows;